import React from 'react';
import '../App.css';
import { Link } from "react-router-dom";

function SummaryItem(props) {
    

  return (
    <div className='subBanner'>
        
    <div className='summary'>
       <div className='icon' style={{backgroundImage: `url("../img/icons/quest-icon.png"))`}}></div>
       <h2>What is Sanda Team dk?</h2>
       <p>Firstly we are a team, passionate about overcoming our own boundaries and encouraging others to overcome theirs! The Team follows these fundamental principles:</p>
      <ul>
        <li>Respect</li>
        <li>Empathy</li>
        <li>Discipline</li>
        <li>Comradery</li>
      </ul>
    </div>
    <div className='summary'>
       <div className='icon' style={{backgroundImage: `url("../img/icons/journey4-icon.png")`}}></div>
       <h2>Begin your journey!</h2>
       <p>SSK - Sanda Team Denmark is open to adults and accommodates all abilities from complete novice to those experienced in martial arts. Get in touch and start your journey in Chinese Boxing with a growing competitive sport of Sanda (SanShou)!</p>
    </div>
</div>
  );
}

export default SummaryItem;