import React from 'react';
import '../App.css';
import { linkData } from '../data/LinkData';
import NavLinks from './NavLinks';
import { useAuth } from '../utils/AuthContext';


function Footer() {
	const {user} = useAuth();

  	return (
     <div className="footer">
			{!user ? (
				linkData.map(function(link) {
					if(link.view === 'public') {
						return (
							<ul><NavLinks
								key={link.id}
								title={link.title}
								url={link.url}
								icon={link.icon}
								subNav={link.subNav}
							/>
							</ul>
						);
					} 
				})

	 		 ) : (
				linkData.map(function(link) {
						return (
							<ul><NavLinks
							key={link.id}
							title={link.title}
							url={link.url}
							icon={link.icon}
							subNav={link.subNav}
						/>
						</ul>
					);
			})
		)
	}
    </div>
  );
}

export default Footer;