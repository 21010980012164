import React from 'react';
import '../App.css';
import { Link } from "react-router-dom";

function NavLinks(props) {

	return (
			<li key={props.id} name={props.id}><Link to={props.url} 
			onClick={props.closeMobileNav}
			
				>
				{props.title}</Link>
				<ul>
				{props.subNav.map(function(subLink) {
					return (
						<li key={subLink.id}><Link to={subLink.url}>{subLink.title}</Link></li>
					); 
				
					})}
				</ul>
			</li>
	);
}

export default NavLinks;