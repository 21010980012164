import React from 'react';
import { useState, useEffect } from 'react';
import '../App.css';

import SummaryItem from '../components/SummaryItem.js';


function getWindowDimensions() {
   const { innerWidth: width, innerHeight: height } = window;
   return {
     width,
     height
   };
 }
 
function useWindowDimensions() {
   const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
 
   useEffect(() => {
     function handleResize() {
       setWindowDimensions(getWindowDimensions());
     }
 
     window.addEventListener('resize', handleResize);
     return () => window.removeEventListener('resize', handleResize);
   }, []);
 
   return windowDimensions;
 }




function Home() {
    
 // Set the title in the tab
 document.title = "Sanda Team Denmark";
 

 const { height, width } = useWindowDimensions();
 console.log("Window Width = " + width);
 
   return (
    <>
     <div className="contentContainer">
      <div className='banner main' style={width <= 1000 ? {  backgroundPositionX: '48%', backgroundImage: `url("../img/tourn2.png")`}: {backgroundImage: `url("../img/tourn2.png")`, backgroundPosition: 'center' }}>
         <div className='title'>
            Sanda Team DK
         </div>
         <div className='titleDesc'>
         in association with <a href="https://sanshoukuan.com/denmark" target="_blank">San Shou Kuan <img src='../img/icons/ssk-logo-icon.png'/></a>
         </div>
     </div>
     <SummaryItem></SummaryItem>
         <div className='pageContent'>
         <h2>A warm welcome from Sanda Team dk</h2>
         <p>If you are interested in mastering self-defense, improving your fitness or possibly harnessing your abilities competitively, then you are where you need to be! Here you will find all the information you need about this Chinese Boxing style.</p>
         <p>The club is new to Denmark and part of <a href="https://sanshoukuan.com/" target="_blank">San Shou Kuan (SSK)</a> this chinese boxing style has crossed the short distance from England across the North Sea and landed in Odense.</p>
         <p>Sanda is not well known to Denmark with only a handful of clubs. SSK is associated with the BCCMA (British Council for Chinese Martial Arts) who annually organise national and European events. There is currently no such national body in Denmark however as a member there is a platform to take part in such events and travel over Europe to meet other members.</p>
         {/* <p>All that being said, SSK - Sanda Team DK is relatively new, therefore as a small club branched from a larger body there is more opportunity to focus on individual members and create bespoke classes to suit.</p> */}
         <p>If you are interested in finding out where your <a href="https://sanshoukuan.com/denmark" target="_blank">nearest class is then follow here</a>.</p>
      </div>
      <div className='pageContentWhite'>
       <h2>About the Team</h2>
       <p>The primary objective of San Shou Kuan’s approach to martial arts is to train students to master effective self defense whilst improving fitness. Sanda Team dk holds this ethos at its core, as SSK is the foundation of its martial arts style.</p>
       <p>Through training students develop at their own pace, gaining confidence in their personal abilities as martial artists. These are the main objectives of Sanda Team dk:</p>
       <ul>
        <li>To improve fitness through combative drills and endurance circuits.</li>
        <li>To teach effective techniques that are utilised in the combative sport of Sanda.</li>
        <li>To encourage self development, promoting confidence in self defence and individual growth.</li>
        <li>To provide a secure and healthy environment for students to train and enhance their own abilities.</li>
        <li>To build a team and inspire comradery.</li>
        <li>To coach individuals towards competitive tournaments (this is not a requirement for training, but an option for those interested in testing their skills competitively).</li>
       </ul>
     </div>
     <div className='pageContent'>
       <h2>Meet the instructor</h2>
       <p>Sifu John Machell, a 2nd Degree Blackbelt in SSK, originally from Watford (England), has opened up a class for new students to join. With over 17 years experience in the martial arts, 5 years of which competing competitively, instructing and coaching, he has the skills and ability to transform beginners into confident, efficient martial artists. He has settled in the town with his Danish wife, son and daughter, although his Danish is improving as an instructor his passion shines through.</p>    
    </div>
      </div>
     </>
   );
 }

export default Home;