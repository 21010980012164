import { React, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { baseURL } from '../shared';
import '../App.css';

function Techniques() {
  // Set the title in the tab
 document.title = "Sanda Team Denmark - Techniques"
 const [technique, setTechnique] = useState([]);
 console.log(useParams());
 let { search } = useParams();

 useEffect(() => {
    fetch(baseURL +'/api/techniques')
        .then((response) => response.json())
        .then((data) => {
            setTechnique(data.techniques);  
            console.log(data.techniques);
        })
        .catch(error => console.error(error));
        
    console.log('Page Loaded');
 }, []);

  return (
    <>
     <div className="contentContainer">
    <div className='banner' style={{ backgroundImage: `url("../img/headkick.png")`, backgroundPositionY: '60px', backgroundPositionX: '40%', minHeight: '600px'}}>
       <div className='title'>
           Sanda Team dk
       </div>
       <div className='titleDesc'>
       Techniques
       </div>
   </div>
   <div className='subBanner'>
            {technique ? (
                technique.map((data) => {
                    return (
                    <div key={data._id} className='summary'>
                        <div className='icon' style={{backgroundImage: `url("../img/icons/login.png")`}}></div>
                        <h2>{data.name}</h2>
                        <p>This technique is used for {data.attribute}.</p>
                    </div>
                    );    
            })
        ) : (
            null
        )}
   </div>
    <div className='pageContent'>
       <h2>About the Team</h2>
       <p>The primary objective of San Shou Kuan’s approach to martial arts is to train students to master effective self defense whilst improving fitness. Sanda Team dk holds this ethos at its core, as SSK is the foundation of its martial arts style.</p>
       <p>Through training students develop at their own pace, gaining confidence in their personal abilities as martial artists. These are the main objectives of Sanda Team dk:</p>
       <ul>
        <li>To improve fitness through combative drills and endurance circuits.</li>
        <li>To teach effective techniques that are utilised in the combative sport of Sanda.</li>
        <li>To encourage self development, promoting confidence in self defence and individual growth.</li>
        <li>To provide a secure and healthy environment for students to train and enhance their own abilities.</li>
        <li>To build a team and inspire comradery.</li>
        <li>To coach individuals towards competitive tournaments (this is not a requirement for training, but only an option for those interested in testing their skills competitively).</li>
       </ul>
     </div>
   </div>
   </>
 );
}

export default Techniques;