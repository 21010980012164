import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
import '../App.css';
import '../Signup.css';
import '../data/CountryList';
import '../data/FormData';
import '../components/CustomDatePicker';
import CustomDatePicker from '../components/CustomDatePicker';

import { baseURL } from '../shared';
import axios from 'axios';


function SignUp() {
   // Set the title in the tab
 document.title = "Sanda Team Denmark - Sign Up"

//get data option data for the form 
 let countries = require('../data/CountryList.json');
 let industry = require('../data/Industry.json');
 let formData = require('../data/FormData.json');


 const form = useRef();
 const [inputs, setInputs] = useState({title: '',firstname: '', surname: '',dob: '',gender: '', industry: '',email: '',telephone: '',address_1: '',address_2: '',town: '',country: '',postcode: '',advert: '',advert_type: '',goals: [],emergency_name: '',emergency_relation: '',emergency_telephone: '',media_consent: '',comments: ''});
 const [selectDate, setSelectDate] = useState('');
 const [filterGoals, setFilterGoals] = useState([]);
 const [error, setError] = useState('');
 const [showForm, setShowForm] = useState(true);
 const [disableIntroState, setDisableIntroState] = useState(true);

 const [btnDisabled, setBtnDisabled] = useState(true);

 const handleChange = (event) => {
    const { name, value} = event.target;

    if (name === 'goals') {
      let exists = filterGoals.find(filter => filter === value);
      if (exists) {
        const updatedFilters = filterGoals.filter(filter => filter !== value);
        setFilterGoals(updatedFilters);
      } else {
        setFilterGoals([...filterGoals, value])
      }
    } else {
   setInputs(values => ({...values, [name]: value}));
   
    }
 }
 useEffect(() => {
  const name = 'goals';

  setInputs(values => ({...values, [name]: filterGoals}));


 }, [filterGoals]);

 useEffect(() => {
    console.log(inputs);
    if (  //About You input validation
          inputs.title && inputs.firstname.length > 2 && inputs.surname.length > 2 && inputs.dob && inputs.gender && inputs.industry && 
          //Contact Info input validation
          inputs.email.length > 5 && inputs.telephone.length > 6 && 
          //Your Address
          inputs.address_1.length > 4 && inputs.country && inputs.postcode.length > 3 &&
          //Additional Info
          inputs.advert && inputs.goals.length > 0 &&
          // Emergency contact info
          inputs.emergency_name > 3 && inputs.emergency_relation && inputs.emergency_telephone > 6 &&
          // Consent to picture
          inputs.media_consent
        ) {
      setBtnDisabled(false)
    } else {
      setBtnDisabled(true)
    } if (inputs.advert === 'Friend' || inputs.advert === 'Family') {
      setDisableIntroState(false)
    } else {
      setDisableIntroState(true)
    }
}, [inputs]);
  
useEffect(() => {
  if (selectDate) {
  const name = 'dob';
  const regex = /^(?:\S+\s+){3}\S+/;
  const value = selectDate.toString().match(regex);

  if (value) {
  setInputs(values => ({...values, [name]: value[0]}));
  }
} else {
  
}

},[selectDate]);

 const handleTelChange = (value ) => {
    const name = 'telephone';
    setInputs(values => ({...values, [name]: value}));
 }
 const handleTel2Change = (value ) => {
    const name = 'emergency_telephone';
    setInputs(values => ({...values, [name]: value}));
 }

 const handleSubmit = (event) => {
   event.preventDefault();
   // Check the email is set and has a valid structure
   if (!inputs.email) {
     setError('No email address added!') 
   } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(inputs.email)) {
    setError('Invalid email address')
   } else {
      var title = inputs.title;
      var firstname = inputs.firstname;
      var surname = inputs.surname;
      var dob = inputs.dob;
      var gender = inputs.gender;
      var industry = inputs.industry;
      var email = inputs.email;
      var telephone = inputs.telephone;
      var address_1 = inputs.address_1;
      var address_2 = inputs.address_2;
      var town = inputs.town;
      var country = inputs.country;
      var postcode = inputs.postcode;
      var advert = inputs.advert;
      var advert_type = inputs.advert_type;
      var goals = inputs.goals;
      var emergency_name = inputs.emergency_name;
      var emergency_relation = inputs.emergency_relation;
      var emergency_telephone = inputs.emergency_telephone;
      var media_consent = inputs.media_consent;
      var comments = inputs.comments;

      setError('');
      axios.post(baseURL + '/signup', {
        title,
        firstname,
        surname,
        dob,
        gender,
        industry,
        email,
        telephone,
        address_1,
        address_2,
        town,
        country,
        postcode,
        advert,
        advert_type,
        goals,
        emergency_name,
        emergency_relation,
        emergency_telephone,
        media_consent,
        comments
      })
      .then(() => {
        alert('Message Sent Successfully');
        event.target.reset();
        setInputs('');
        setShowForm(false);
      })
      .catch(() => alert('Snap, something is not quite right!'))
    }

    console.log('It would have been sent now!!!')

     event.target.reset();
     setInputs('');
     setShowForm(false);
   }
 // Back button navigate to home page
 let navigate = useNavigate(); 
 const routeChange = () =>{ 
   let path = `../`; 
   navigate(path);
 }

  return (
    <>
    <div className="contentContainer">
    <div className='banner' style={{ backgroundImage:  `url("../img/sanda1.jpg")`, backgroundPositionY: '65px', backgroundPositionX: '37%', minHeight: '600px'}}>
       <div className='title'>
           Sign Up Today
       </div>
       <div className='titleDesc'>
       </div>
   </div>
   <div className='subBanner'>
      
          {showForm ? (
          <form name='signup-form'className="signup-form-container" ref={form} onSubmit={handleSubmit}>
          <fieldset>
          <legend>About You</legend>
          <div className="signup-form-group">
              <label htmlFor='title'>Title <span>*</span></label>
              <select className="signup-form-control" type="text" id='title' name='title' placeholder="Title"  value={inputs.title} onChange={handleChange}>
                {formData.title.map(function(title) {
                  return (
                    <option key={title.name} value={title.name}>{title.name}</option>
                  );
                })}
              </select>
            </div>
          <div className="signup-form-group">
              <label htmlFor='firstname'>First Name <span>*</span></label>
              <input autoFocus={true} className="signup-form-control" type="text" id='firstname' name='firstname' placeholder="First Name" value={inputs.firstname} onChange={handleChange}/>
            </div>
            <div className="signup-form-group">
              <label hmtlfor='surname'>Surname <span>*</span></label>
              <input className="signup-form-control" type="text" id='surname' name='surname' placeholder="Surname" value={inputs.surname} onChange={handleChange}/>
            </div>
            <div className="signup-form-group">
              <label>Date of Birth <span>*</span></label>
              <CustomDatePicker 
                name='dob'
                id='dob'
                placeholder='Date of birth'
                inputSelect={setSelectDate}
                inputDate={selectDate}
              />
            </div>
            <div className="signup-form-group" style={{flexDirection: 'row'}}>
              <label className='radio-select'>Gender <span>*</span></label>
                <input type="radio" className='radio' id='male' name='gender' value='male' onChange={handleChange}/>
                <label htmlFor="male" className='radio-label'>Male</label>
                <input type="radio" className='radio' id="female" name="gender" value="female" onChange={handleChange}/>
                <label htmlFor="female" className='radio-label'>Female</label>
            </div>
            <div className="signup-form-group">
              <label>Industry <span>*</span></label>
              <select className="signup-form-control" type="text" name='industry' placeholder="Industry" value={inputs.industry} onChange={handleChange}>
                {industry.map(function(industry) {
				          return (
                    <option key={industry.name} value={industry.name}>{industry.name}</option>
				                ); 
			
				        })}
              </select>
            </div>
            </fieldset>
            <fieldset>
            <legend>Contact Info</legend>
            <div className="signup-form-group">
              <label>Your Email <span>* (This will be your online username)</span></label>
              <input className="signup-form-control" type="text" name="email" placeholder="Email"  value={inputs.email} onChange={handleChange}/>
              <span></span>
            </div>
            <div className="signup-form-group">
            <label style={{width: '100%'}} >Telephone Number <span>*</span></label>
            <PhoneInput
                inputProps={{
                required: true,
                autoFocus: false,
                name:'tel',
                }}
                country={'dk'}
                value={inputs.tel}  
                onChange={handleTelChange} 
                placeholder="Enter Phone Number"       
            />
            </div>
            </fieldset>
            <fieldset>
            <legend>Your Address</legend>
            <div className="signup-form-group">
              <label htmlFor='address1'>Address Line 1 <span>*</span></label>
              <input className="signup-form-control" type="text" name="address_1" placeholder="Address Line 1" value={inputs.address_1} onChange={handleChange}/>
            </div>
            <div className="signup-form-group">
              <label htmlFor='address2'>Address Line 2 <span>(Optional)</span></label>
              <input className="signup-form-control" type="text" name="address_2" placeholder="Address Line 1" value={inputs.address_2} onChange={handleChange}/>
            </div>
            <div className="signup-form-group">
              <label htmlFor='town'>Town</label>
              <input className="signup-form-control" type="text" name="town" placeholder="Town" value={inputs.town} onChange={handleChange}/>
            </div>
            <div className="signup-form-group">
              <label>Country <span>*</span></label>
              <div className='.react-tel-input flag-dropdown'></div>
              <select className="signup-form-control" type="text" name='country' placeholder="Country" value={inputs.country} onChange={handleChange}>
              <option value=''></option>
                {countries.map(function(countries) {
				          return (
                    <option key={countries.code} value={countries.name}>{countries.name}</option>
			          	); 
			
			        	})}
              </select>
            </div>
            <div className="signup-form-group">
              <label>Postcode <span>*</span></label>
              <input className="signup-form-control" type="text" name="postcode" placeholder="postcode" value={inputs.postcode} onChange={handleChange}/>
            </div>
          </fieldset>
          <fieldset>
          <legend>Additional Information</legend>
          <div className="signup-form-group">
              <label>How did you hear about the class? <span>*</span></label>
              <select className="signup-form-control" type="text" name='advert' placeholder="How did you hear about the class?" value={inputs.advert} onChange={handleChange}>
                {formData.advert.map(function(advert) {
                  return (
                    <option key={advert.name} value={advert.name}>{advert.label}</option>
                  );
                })}
                
              </select>
            </div>
          <div className="signup-form-group">
              <label>If friend or family, who? (if any) <span></span></label>
              <input disabled={disableIntroState} className="signup-form-control" type="text" name='advert_type' placeholder="Enter their name" value={inputs.advert_type} onChange={handleChange}/>
            </div>
            <div className="signup-form-group large-width">
              <label>Help us to understand your goals <span>*</span></label>
              <div className='checkbox-container'>
              {formData.goals.map(function(goals) {
				        return (
                    <div className='checkbox-item' key={goals.name}>
                    <input id={goals.name} type="checkbox" name='goals' value={goals.name} onChange={handleChange}/>
                    <label htmlFor={goals.name}>{goals.name}</label>
                    </div>
				        ); 
				      })}
                </div>
            </div>
            <div className="signup-form-group">
              <label>Emergency Contact's Details <span>*</span></label>
              <input className="signup-form-control" type="text" name='emergency_name' placeholder="Emergency Name" value={inputs.emergency_name} onChange={handleChange}/>
              <label>Their relationship with you <span>*</span></label>
              <select className="signup-form-control" type="text" name='emergency_relation' placeholder="How did you hear about the class?" value={inputs.emergency_relation} onChange={handleChange}>
                {formData.relation.map(function(relation) {
				        return (
                  <option key={relation.name} value={relation.name}>{relation.name}</option>
				        ); 
				      })}
              </select>
            </div>


            <div className="signup-form-group">
              <label>Emergency Contact's Telephone <span>*</span></label>
              <PhoneInput
                inputProps={{
                required: true,
                autoFocus: false,
                name:'tel',
                }}
                country={'dk'}
                value={inputs.emergency_telephone}  
                onChange={handleTel2Change} 
                placeholder="Enter their Phone Number"
            />
            </div>
            <div className="signup-form-group no-input-margin">
              <label>Photograph Permission <span>*</span></label>
              <div>
                <label className='permission-box'>Sometimes during a training session pictures will be taken to be used in marketing material and on social media to help promote the classes. Please select whether you give consent or not to have your picture taken and used according to our marketing policy.</label>
              </div>
              <div style={{textAlign: 'left'}}>
                <input type="radio" className='radio' id='permission' name='media_consent' value='true' onChange={handleChange}/>
                <label htmlFor="permission" className='radio-label'>Give consent</label>
                <input type="radio" className='radio' id="not-permitted" name="media_consent" value="false" onChange={handleChange}/>
                <label htmlFor="not-permitted" className='radio-label'>Do not consent</label>
                </div>
              </div>
            <div className="signup-form-group">
              <label>Any note/ Comments</label>
              <textarea className="signup-form-control" type="text" name='comments' rows="4" cols="50" placeholder="Share with us anything else you think we should know." value={inputs.comments} onChange={handleChange}/>
            </div>
            </fieldset>
            <button className="btn signup-btn-primary btn-block" id="contact"  data-bs-hover-animate="shake" disabled={btnDisabled} type="submit">Submit Application</button>
            <span id="alert">{error}</span>
          </form> ) : (
          <div className="signup-form-container"><div className="notice-text"><p>Thank You! Your message was successfully sent. Someone will be in touch, usually within 48 hours.</p></div>
          <button className="btn signup-btn-primary btn-block" id="contact"  data-bs-hover-animate="shake" disabled="" type="button" onClick={routeChange}>Back To Home</button></div> )
          }
        </div>
   </div>
   </>
  );
}

export default SignUp;