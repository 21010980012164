import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { baseURL } from '../shared';
import axios from 'axios';
import '../App.css';


function Contact() {

  document.title = "Sanda Team Denmark - Contact"
  const form = useRef();
  const [inputs, setInputs] = useState({name: '', email: '', subject: '', message: ''});
  const [error, setError] = useState('');
  const [showForm, setShowForm] = useState(true);

  const [btnDisabled, setBtnDisabled] = useState(true);

  
  useEffect(() => {
    console.log(inputs);
     if ( 
      inputs.name.length > 3 && inputs.email.length > 4 && inputs.subject.length > 3 && inputs.message.length > 4
        ) {
      setBtnDisabled(false)
     } else {
      setBtnDisabled(true)
      }
    }, [inputs]);
  
  const handleChange = (event) => {
    const { name, value} = event.target;
    setInputs(values => ({...values, [name]: value}));
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setBtnDisabled(true);
    // Check the email is set and has a valid structure
    if (!inputs.email) {
      setError('No email address added!') 
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(inputs.email)) {
     setError('Invalid email address')
    } else {
      var name = inputs.name;
      var recipient_email = inputs.email;
      var subject = inputs.subject;
      var message = inputs.message;

      setError('');
      axios.post(baseURL + '/send_email', {
        name,
        recipient_email,
        subject,
        message
      })
      .then(() => {
        event.target.reset();
        //setInputs('');
        setShowForm(false);
      })
      .catch(() => alert('Snap, something is not quite right! Please try again.'))
    }
  }
  // Back button navigate to home page
  let navigate = useNavigate(); 
  const routeChange = () =>{ 
    let path = `../`; 
    navigate(path);
  }



  return (
    <div className="contentContainer contact">
      <div className='banner' style={{ backgroundImage: `url("../img/bow.jpg")`, backgroundPositionY: '-90px', backgroundPositionX: '40%' }}>
      <div className='title'>{showForm ? (<>Get in touch!</>) : (<>Message Sent!</>)}</div>
    </div>
    <div className='subBanner' style={{ display: 'inline' }}>
    <div className="contact-form">
          {showForm ? (
          <form className="form-container" ref={form} onSubmit={handleSubmit}>
          <div className="form-group">
              <label>Your Name <span>*</span></label>
              <input className="form-control" type="text" name='name' placeholder="Name" autoFocus="true" required value={inputs.username} onChange={handleChange}/>
            </div>
            <div className="form-group">
              <label>Your Email <span>*</span></label>
              <input className="form-control" type="email" name="email" placeholder="Email" required value={inputs.email} onChange={handleChange}/>
            </div>
            <div className="form-group">
            <label>The Subject <span>*</span></label>
              <input className="form-control" type="text" name="subject" placeholder="Subject" required value={inputs.subject} onChange={handleChange}/>
            </div>
            <div className="form-group">
              <label>Your message <span>*</span></label>
              <textarea className="form-control message" type="enquiry" name="message" placeholder="Message" required rows="20" cols="50" value={inputs.message} onChange={handleChange}/>
            </div>
            <button className="btn btn-primary btn-block" id="contact"  data-bs-hover-animate="shake" disabled={btnDisabled} type="submit">Send</button>
            <span id="alert">{error}</span>
          </form> ) : (
          <div className="form-container"><div className="notice-text"><p>Thanks for your message {inputs.name}!</p><p>Your message was successfully sent and you will receive a confirmation email. Someone will be in touch, usually within 48 hours.</p></div>
          <button className="btn btn-primary btn-block" id="contact"  data-bs-hover-animate="shake" disabled="" type="button" onClick={routeChange}>Back To Home</button></div> )
          }
        </div>
        </div>
    </div>
  );
}

export default Contact;
