import { createContext, useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { baseURL } from '../shared';
import axios from 'axios';
import Loader from '../components/Loader';


const AuthContext = createContext()

export const AuthProvider = ({children}) => {
        const navigate = useNavigate();

        const [loading, setLoading] = useState(false)
        const [user, setUser] = useState(null)

        useEffect(() => {
            checkUserStatus()
            console.log('checking user status')
         }, [])

         // FUNCTION TO USED TO AUTHENTICATE THE USER AND RETURN JWT TOKENS
         const loginUser = async (userInfo) => {
            setLoading(false)

                    var username = userInfo.username;
                    var password = userInfo.password;
                    
                    return axios.post(baseURL + '/login', {
                    username,
                    password
                    })
                    .then((res) => {
                    localStorage.setItem('accessToken', res.data.accessToken)
                    localStorage.setItem('refreshToken', res.data.refreshToken)
                    localStorage.setItem('user', true)
                    setLoading(false);
                    setUser(true);
                    navigate('/');
                    //TO DO - 1-ADD A LOGGING IN MODAL 2-CHANGE STATE TO LOGGED IN ON APP 3-MAKE PROTECTIVE ROUTES THAT CHECK IF LOGGED IN 4-BACKEND SAVE JWT TOKEN AND REFRESH TOKEN TO DB 
       
                    })
                    .catch((error) => {
                        setLoading(false)
                        return error
                    })
            
             }
         // FUNCTION USED TO LOG THE USER OUT BY DELETING THE TOKEN LOCALLY AND IN THE DATABASE
         const logoutUser = async () => {
                var refreshToken = localStorage.getItem('refreshToken');
                    console.log(refreshToken)
                    return axios.delete(baseURL + '/logout'+refreshToken)
                    .then((res)=> {
                        setUser(false);
                        localStorage.clear()
                        console.log('Logged out')
                    })
                    .catch((error) => {
                        setLoading(false)
                        return error
                    })
                }

         const registerUser = async (userInfo) => {
            setLoading(true)

           /*  try{
                
                let response = await account.create(ID.unique(), userInfo.email, userInfo.password1, userInfo.name);
        
                await account.createEmailSession(userInfo.email, userInfo.password1)
                let accountDetails = await account.get();
                setUser(accountDetails)
                navigate('/')
            }catch(error){
                console.error(error)
            }
        
            setLoading(false) */
         }

         const checkUserStatus = () => {
            
            var user = localStorage.getItem('user');
            if(user) {
                setUser(user)
            } else {
                console.log('No user')
            }
            setLoading(false)
         }
        

        const contextData = {
            user,
            loginUser,
            logoutUser,
            registerUser
        }

    return(
        <AuthContext.Provider value={contextData}>
            {loading ? <Loader /> : children}
        </AuthContext.Provider>
    )
}

//Custom Hook
export const useAuth = ()=> {return useContext(AuthContext)}

export default AuthContext;