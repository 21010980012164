import React from 'react';
import '../App.css';

function SSK() {
  // Set the title in the tab
 document.title = "Sanda Team Denmark - About Us"

  return (
    <>
     <div className="contentContainer">
    <div className='banner' style={{ backgroundImage: `url("../img/headkick.png")`, backgroundPositionY: '60px', backgroundPositionX: '40%', minHeight: '600px'}}>
       <div className='title'>
           Sanda Team dk
       </div>
       <div className='titleDesc'>
       About Us
       </div>
   </div>
   <div className='subBanner'>
            <div className='summary'>
               <div className='icon' style={{backgroundImage: `url("../img/icons/login.png")`}}></div>
               <h2>Login or Create an Account</h2>
               <p>With an account you have access to additional content within the members area, aka 'The Dojo'. Here you can browse training material, including videos, coaching tips and much more. Get in touch and signup today to learn more.</p>
            </div>
            <div className='summary'>
               <div className='icon' style={{backgroundImage: `url("../img/icons/journey4-icon.png")`}}></div>
               <h2>Begin your journey!</h2>
               <p>SSK - Sanda Team Denmark is open to everyone and accommodates all abilities from complete novice to those experienced in martial arts. Get in touch and start your journey in Chinese Boxing with a growing competitive sport of Sanda (San Da)!</p>
            </div>
   </div>
    <div className='pageContent'>
       <h2>About the Team</h2>
       <p>The primary objective of San Shou Kuan’s approach to martial arts is to train students to master effective self defense whilst improving fitness. Sanda Team dk holds this ethos at its core, as SSK is the foundation of its martial arts style.</p>
       <p>Through training students develop at their own pace, gaining confidence in their personal abilities as martial artists. These are the main objectives of Sanda Team dk:</p>
       <ul>
        <li>To improve fitness through combative drills and endurance circuits.</li>
        <li>To teach effective techniques that are utilised in the combative sport of Sanda.</li>
        <li>To encourage self development, promoting confidence in self defence and individual growth.</li>
        <li>To provide a secure and healthy environment for students to train and enhance their own abilities.</li>
        <li>To build a team and inspire comradery.</li>
        <li>To coach individuals towards competitive tournaments (this is not a requirement for training, but an option for those interested in testing their skills competitively).</li>
       </ul>
     </div>
    <div className='pageContentWhite'>
       <h2>The Origins Sanda Team dk</h2>
       <p>San Shou Kuan – is a Chinese Kickboxing Association. It formed from a group of forward looking martial art instructors led by two Chinese Kickboxing Senior Masters, Peter Kennedy and David Nicholls MSc. (Sports and Exercise Science) who have over 50 years teaching experience in Martial Arts and working with other senior instructors from a variety of backgrounds including active military instructors in Special forces and instructors from other “styles” or schools. (Supplemented with training in various UK boxing clubs and Thai boxing training camps in Thailand).</p>
       <blockquote>
        <p>– We are an “open style” willing to listen as you never stop learning in Martial Arts.</p>
       </blockquote>
    </div>
    <div className='pageContent'>
       <h2>About SSK Instructors</h2>
       <p>The soul of SSK is in its instructors and its members. SSK instructors are black belts who have extensive knowledge of the class syllabus, as they themselves once began as white belts and journeyed through the styles grading system to become black belts.</p>
       <p>There are more than a dozen instructors throughout the UK and a new instructor has started teaching in Denmark.</p> 
    </div>
    <div className='pageContent'>
       <h2>Meet the instructor</h2>
       <p>Sifu John Machell, a 2nd Degree Blackbelt in SSK, originally from Watford (England), has opened up a class for new students to join. With over 17 years experience in the martial arts, 5 years of which competing competitively, instructing and coaching, he has the skills and ability to transform beginners into confident, efficient martial artists. He has settled in the town with his Danish wife, son and daughter, although his Danish is improving as an instructor his passion shines through.</p>    
    </div>
   </div>
   </>
 );
}

export default SSK;