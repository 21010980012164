import { React, useEffect, useState }from 'react';
import { Link } from 'react-router-dom';
import '../App.css';
import MobileNav from './MobileNav';
import { useAuth } from '../utils/AuthContext';

function Header() {
    // set mobile nav
    const [openMobileNav, setOpenMobileNav] = useState(false);

    // check if logged in
    const {user, logoutUser} = useAuth();

    const closeMobileNav = () => setOpenMobileNav(false);

     // set Header FadIn and Out
     const [showHeader, setShowHeader] = useState(true);
     const [lastScrollY, setLastScrollY] = useState(0);

    const controlHeader = () => {
    if (typeof window !== 'undefined') { 
      if (!openMobileNav) {
        if (window.scrollY > 60 && window.scrollY > lastScrollY ) { // if scroll down hide the navbar
          setShowHeader(false); 
        } else { // if scroll up show the navbar
          setShowHeader(true);  
        }
    } else {
      setShowHeader(true); 
    }
      // remember current page location to use in the next move
      setLastScrollY(window.scrollY);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', controlHeader);

      // cleanup function
      return () => {
        window.removeEventListener('scroll', controlHeader);
      };
    }
  }, [lastScrollY]);
  
  return (
    <>
      <div className="header">
        {/* <div className="header-profile">
        {user ? (
          <>
          <h1 className='profileBtn aR'><Link onClick={logoutUser}>Logout</Link></h1>
          <h1 className='profileBtn aR'><Link to='/login'>Profile</Link></h1>
          </>
        ): (
          <>
          <h1 className='profileBtn aR'><Link to='/register'>Sign Up</Link></h1>
          <h1 className='profileBtn aR'><Link to='/login'>Login</Link></h1>
          </>
        )}
        
        </div> */}
        <h1 className='aL'>Sanda Team Denmark - Chinese Boxing Club</h1>
      </div>
      <div className={`mobileHeader ${!showHeader && 'hide'}`}>
          <div className='header-btn menu-ic'><Link to='/'></Link></div>
          <div className='header-logo'></div>
          {/* <div className='head er-btn profile-ic'></div> */}
          <div className={`burger-menu ${openMobileNav ? "active" : ""}`}
             onClick={() => setOpenMobileNav(!openMobileNav)}
          >
            <div></div>
            <div></div>
            <div></div>
          </div>
          </div>
     <MobileNav isOpen={openMobileNav} closeMobileNav={closeMobileNav}/>
    </>
  );
}

export default Header;