import React from 'react';
import './App.css';

import { Routes, Route } from 'react-router-dom';

//Components of each page
import Header from './components/Header';
import Footer from './components/Footer';
import ScrollToTopArrow from './components/ScrollToTopArrow';
import SideBar from './components/SideBar';

//Pages 
import SSK from './pages/SSK';
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import ErrorPage from './pages/ErrorPage';
import Sanda from './pages/Sanda';
import SignUp from './pages/SignUp';
import Login from './pages/Login';
import Register from './pages/Register';
import Contact from './pages/Contact';
import Techniques from './pages/Techniques';
import OdenseClass from './pages/OdenseClass';
import LeiTai from './pages/LeiTai';


//Other dependencies
import { baseURL } from './shared';
import PrivateRoutes from './utils/PrivateRoutes';
import { AuthProvider } from './utils/AuthContext'


function App() {

  return (
    <div className="App">
    <AuthProvider>
     <Header />
     <SideBar />
     <ScrollToTopArrow />
      <Routes>
        <Route index element={<Home />} />
        <Route element={<PrivateRoutes />}>
          <Route path="/signup" element={<SignUp />} />
          <Route path="/leitai" element={<LeiTai />} />
        </Route>
        <Route path="/ssk" element={<SSK />} />
        <Route path="/about_us" element={<AboutUs />} />
        <Route path="/sanda" element={<Sanda />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/odense" element={<OdenseClass />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/techniques" element={<Techniques />} />
        <Route 
            path="/techniques/:search" 
            element={<Techniques />}
        />
        <Route path="*" element={<ErrorPage />}/>
     </Routes>
     <Footer />
     </AuthProvider>
    </div>
  );
}

export default App;
