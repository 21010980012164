import React from 'react';
import '../App.css';
import { linkData } from '../data/LinkData';
import NavLinks from './NavLinks';
import { useAuth } from '../utils/AuthContext';

function MobileNav({isOpen, closeMobileNav}) {
  const {user} = useAuth();

  return (
    <div className="mobileMenu"
	style={{
        opacity: !isOpen ? 0 : 1 ,
		visibility: !isOpen ? "hidden" : "visible" ,
      }}
	  >
      <ul className="sideNavContainer">
	  {!user ? (
				linkData.map(function(link) {
					if(link.view === 'public') {
					return (
						<NavLinks
							key={link.id}
							title={link.title}
							closeMobileNav={closeMobileNav}
							url={link.url}
							icon={link.icon}
							subNav={link.subNav}
						/>
					);
				} 
			})
	  ) : (
		linkData.map(function(link) {
			return (
					<NavLinks
						key={link.id}
						title={link.title}
						closeMobileNav={closeMobileNav}
						url={link.url}
						icon={link.icon}
						subNav={link.subNav}
					/>
					)
				})
			)
	}
	</ul>
    </div>
  );
}

export default MobileNav;