import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Link } from "react-router-dom";
import '../App.css';
import { useAuth } from '../utils/AuthContext';


function Login() {
   // Set the title in the tab
 document.title = "Sanda Team Denmark - Login"

 const {user, loginUser} = useAuth()
 const loginForm = useRef();
 const [inputs, setInputs] = useState({username: '', password: ''});
 const [error, setError] = useState('');

 const [btnDisabled, setBtnDisabled] = useState(true);
 const [loading, setLoading] = useState(false)

 const handleChange = (event) => {
    const { name, value} = event.target;

   setInputs(values => ({...values, [name]: value}));
   
    }

 useEffect(() => {
    if (!inputs.username && !inputs.password) {
        setError('')
    }
    else if (  //Input validation
          inputs.username.length > 6 && inputs.password.length >= 8
        ) {
      setBtnDisabled(false)
    } else {
      setBtnDisabled(true)
    }
}, [inputs]);

useEffect(() => {
    //Redirect if user is logged in - not need to see login page
    if(user) {
    routeChange(); 
    }
}, [user])
  

 const handleSubmit = (event) => {
   event.preventDefault();
   setBtnDisabled(true);
    // Check the email is set and has a valid structure
    if (!inputs.username) {
       setError('No email address added!');
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(inputs.username)) {
       setError('Invalid email address')
    } else {
    setLoading(true)
        // Use Auth Context Function  
      var authResponse = loginUser(inputs);
      
      // Show error
      authResponse.then(function(result) {
        

        if(result) {
          var error = result.response.data.data.error
          
            console.log(error);
            setError(error);
            setLoading(false);
            inputs.password('');
            inputs.username('');
        }
       
      })
        
    }

   }
 // Back button navigate to home page
 let navigate = useNavigate(); 
 const routeChange = () =>{ 
   let path = `/`; 
   navigate(path);
 }

  return (
    <div className="contentContainer">
    <div className='banner' style={{ backgroundImage:  `url("../img/sanda1.jpg")`, backgroundPositionY: '65px', backgroundPositionX: '37%', minHeight: '600px'}}>
       <div className='title'>
           Login
       </div>
   </div>
   <div className='subBanner'  style={{ display: 'inline' }}>
   <div className="contact-form">
          <form name='login-form'className="form-container" style={{ height: 'auto', width: '400px' }} ref={loginForm} onSubmit={handleSubmit}>
          <div className="form-group">
              <label htmlFor='email address'>Email Address<span></span></label>
              <input autoFocus={true} className="form-control" type="email" id='username' name='username' placeholder="Email address" value={inputs.username} onChange={handleChange}/>
           </div>
           <div className="form-group">
              <label htmlFor='password'>Password<span></span></label>
              <input className="form-control" type="password" id='password' name='password' placeholder="Password" value={inputs.password} onChange={handleChange}/>
            </div>          
          <span className='quick-link'><Link to='/register'>Not a member!</Link></span>
          <span className='alert'>{error}</span>
            <button className="btn btn-primary btn-block" id="contact"  data-bs-hover-animate="shake" disabled={btnDisabled} type="submit">{loading ? ('Loading...') : ('Login')}</button>
          </form>
    </div>
   </div>
</div>
  );
}

export default Login;