import { useEffect, useState } from "react";

function ScrollToTopArrow() {
    const [showReturnTop, setShowReturnTop] = useState(false);
    const [animateReturnTop, setAnimateReturnTop] = useState(false);
    
    const goToTop = () => {
        setAnimateReturnTop(true);
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };
    
    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 300) {
                setShowReturnTop(true);
            } else {
                setShowReturnTop(false);
                setAnimateReturnTop(false);
            }
        });
    }, []);

    return (
       <div className={`return-to-top ${showReturnTop && 'show'} ${animateReturnTop && 'active'} `} onClick={goToTop}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            {/* <span>⇮</span> */}
        </div> 
    );
}

export default ScrollToTopArrow;