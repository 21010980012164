import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function CustomDatePicker({inputDate, inputSelect}) {

  
  return (
    <DatePicker 
      id='dob'
      selected={inputDate}
      onChange={(date) => inputSelect(date)} 
      showYearDropdown
      dateFormatCalendar="MMMM"
      yearDropdownItemNumber={64}
      scrollableYearDropdown
    />
  );
}

export default CustomDatePicker;