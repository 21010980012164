import React from "react";
import '../App.css';

function Loader(error) {

return(
<div className="loader">
<div className="loading">
     <p>L</p>
     <p>O</p>
     <p>A</p>
     <p>D</p>
     <p>I</p>
     <p>N</p>
     <p>G</p>
     <p>.</p>
     <p>.</p>
     <p>.</p>
   </div>
   </div>
);

}
export default Loader;