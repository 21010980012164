import React from 'react';
import '../App.css';
import Error from '../components/Error';

function ErrorPage() {
  document.title = "Sanda Team Denmark - Page not Found"
  return (
    <Error type='notFound'/>
  );
}

export default ErrorPage;