export const linkData = [
	{
		id: 1,
		title: "Sanda Team dk",
		url: "/",
		icon: "home",
		view: "public",
		subNav: [{
				id: 1.1,
				title: "Welcome",
				url: "/",
				icon: "home",
				},
				{
				id: 1.2,
				title: "The Instructor",
				url: "/",
				icon: "home",
				},
				{
				id: 1.3,
				title: "Class Structure",
				url: "/",
				icon: "home",
				},
				]
	},
	{
		id: 3,
		title: "SSK",
		url: "/ssk",
		icon: "ssk",
		view: "public",
		subNav: [{
			    id: 3.1,
				title: "About",
				url: "/",
				icon: "ssk",
				},
				{
				id: 3.2,
				title: "Origin",
				url: "/",
				icon: "ssk",
				},
				{
				id: 3.3,
				title: "Ambition",
				url: "/",
				icon: "ssk",
				}
				]
	},
	{
		id: 5,
		title: "The Lei Tai",
		url: "/leitai",
		icon: "sanda",
		view: "private",
		subNav: [{
			    id: 5.1,
				title: "Members Area",
				url: "/",
				icon: "sanda",
				},
				{
				id: 5.2,	
				title: "Classes",
				url: "/",
				icon: "sanda",
				},
				{
				id: 5.3,
				title: "Techniques",
				url: "/",
				icon: "sanda",
				}
				]
	},
	{
		id: 6,
		title: "Odense Class",
		url: "/odense",
		icon: "sanda",
		view: "public",
		subNav: [{
			    id: 6.1,
				title: "Expectations",
				url: "/",
				icon: "sanda",
				},
				{
				id: 6.2,	
				title: "Interested?",
				url: "/",
				icon: "sanda",
				}
				]
	},
	{
		id: 7,
		title: "Contact",
		url: "/contact",
		icon: "contact",
		view: "public",
		subNav: []
	}
];