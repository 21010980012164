import React from "react";
import '../App.css';

function Error(props) {

return(
<div className="loader">
      <div className='errorPage'>
        <h1>TKO</h1>
        {props.type === 'notFound' && <h2>Sorry page not found, press back in your browser and try another link!</h2>}
        {props.type === 'ApolloError: Failed to fetch' && <h2>We are knocked out by a technical issue, please refresh your browser or try again later!</h2>}
        {props.type === 'ApolloError: Response not successful: Received status code 401' && <h2>401 Error: Unauthorized. Please log in and try again! </h2>}
        {props.type === 'Forbidden access' && <h2>{props.type}, please login to view this page! </h2>}
        {props.type === 'no data' && <h2>Unable to reach the server, please press back and try again later!</h2>}
      </div>
    </div>
);

}
export default Error;