import { React, useEffect, useState } from 'react';
import { baseURL } from '../shared';
import '../App.css';


function Leitai() {
   // Set the title in the tab
 document.title = "Sanda Team Denmark - The Lei Tai"

 const [pageContent, setPageContent] = useState([])
 console.log('page landed on')

 useEffect(() => {
   fetch(baseURL +'/members')
       .then((response) => response.json())
       .then((data) => {
           setPageContent(data);  
           console.log(data);
       })
       .catch(error => console.error(error));
       
   console.log('Page Loaded');
   console.log(pageContent.data)
}, []);

  return (
    <>
    <div className="contentContainer">
    <div className='banner' style={{ backgroundImage:  `url("../img/sanda1.jpg")`, backgroundPositionY: '65px', backgroundPositionX: '37%', minHeight: '600px'}}>
       <div className='title'>
           Members Area
       </div>
       <div className='titleDesc'>
       
       </div>
   </div>
   <div className='subBanner'>
   {pageContent ? (              
                pageContent.map((data) => {
                  console.log("PageData: "+data)
                    return (
                    <div className='pageContent'>
                      <h2>Welcome to the members area!</h2>
                      <p>Content here will be updated regularly so stay tuned.</p>
                    </div>
                    );    
            })
        ) : (
            <>
             <div className='pageContent'>
                      <h2>ACCESS DENIED</h2>
                      <p>Not sure why you can view this. You need to either login or signup to view this content!</p>
                    </div>
            </>
        )}
      
            {/* <div className='summary'>
               <div className='icon' style={{backgroundImage: `url("../img/icons/quest-icon.png"))`}}></div>
               <h2>What is Sanda?</h2>
               <p>Sanda or SanShou is the combative sport associated with Chinese Martial Arts. As the Arts vary in technique and style, Sanda is the platform for the different styles to meet competitively and perform within a set rule base, putting a martial artist's skills to the test.</p>
               <p>Sanda competitions are fast paced and combatants use a variety of explosive techniques to score points for cleanly landed strikes, much like in boxing. Fights are performed on a raised platform, known as a Lei Tai, which has no boundary ropes or barrier so the fighters can be expelled from the arena. This can cost the fighter points and is a great strategy to lower their stamina if not knock their wind out at the least.</p>
            </div>
            <div className='summary'>
               <div className='icon' style={{backgroundImage: `url("../img/icons/journey4-icon.png")`}}></div>
               <h2>Sparring & Competitions</h2>
               <p>Free sparring is incorporated into each class to allow students the opportunity to practice the techniques they have learnt and apply the applications in a controlled but realistic environment. For a swimmer to practice their sport, they have to get wet, much is the same with martial arts.</p>
               <p>Sanda Team Denmark provides the opportunity for students to take their training to the next level and harness their skills competitively on the lei tai.</p>
            </div> */}
   </div>
     {/*   <div className='pageContent'>
       <h2>What is Sanda and how does SSK fit in?</h2>
       <p>The Primary objective of San Shou Kuan’s approach to martial arts is to train students to master effective self defence whilst improving fitness. From this, the student gains confidence in their personal abilities as a martial artist. San Shou Kuan is a practical martial art based around its members to help them strive for self improvement. The style uses both coaching of individuals together with class based tuition.</p>
       <blockquote><p>“Martial Arts by Martial Artists for aspiring Martial Artists ! – No Politics, No Egos” .</p></blockquote>
       <p>San Shou Kuan translates as Chinese Kickboxing School and classes are taught all over England. San Shou Kuan is not a new style but an amalgam of the very best of traditional Northern Long Fist (Ch’arng Ch’uen) Shaolin Kungfu with the best aspects of Western Kickboxing. This makes San Shou Kuan predominantly a combative striking art that teaches the student explosive kicking, powerful punching techniques (Punch, chop, elbow, knee and kicks) and takedowns used in Sanda.</p>
    </div> */}
   </div>
   </>
  );
}

export default Leitai;