import React from 'react';
import '../App.css';
import { Link } from "react-router-dom";
import { linkData } from '../data/LinkData';
import NavLinks from './NavLinks';
import { useAuth } from '../utils/AuthContext';

function SideBar() {
  const {user} = useAuth();

  

  return (
    <div className="sideContainer">
      <Link to="/">
	  <div className='logo'></div>
	  </Link>
	  <ul className="sideNavContainer">
	  {!user ? (
				linkData.map(function(link) {
					if(link.view === 'public') {
						return (
							<NavLinks
								key={link.id}
								title={link.title}
								url={link.url}
								icon={link.icon}
								subNav={link.subNav}
							/>
						);
					} 
				})

	 		 ) : (
				linkData.map(function(link) {
						return (
						<NavLinks
							key={link.id}
							title={link.title}
							url={link.url}
							icon={link.icon}
							subNav={link.subNav}
						/>
					);
			})
		)
	}
      
     
	  </ul>
    </div>
  );
}

export default SideBar;