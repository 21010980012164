import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { baseURL } from '../shared';
import axios from 'axios';
import '../App.css';


function RegisterInterest() {

  document.title = "Sanda Team Denmark - Show your interest"
  const form = useRef();
  const [inputs, setInputs] = useState({name: '', adult: '', email: '', style: '', coach: '', updates: '', interest: ''});
  const [error, setError] = useState('');
  const [showForm, setShowForm] = useState(true);
  const [showReturn, setShowReturn] = useState(false);

  const [btnDisabled, setBtnDisabled] = useState(true);


  useEffect(() => {
    console.log(inputs);
    if ( 
      inputs.name.length > 3 && inputs.email.length > 4 && inputs.adult && inputs.style.length > 3 && inputs.coach && inputs.updates && inputs.interest.length > 4
        ) {
      setBtnDisabled(false)
    } else {
      setBtnDisabled(true)
    }
}, [inputs]);


  const handleChange = (event) => {
      const { name, value} = event.target;
      setInputs(values => ({...values, [name]: value}));
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setBtnDisabled(true);
    // Check the email is set and has a valid structure
    if (!inputs.email) {
      setError('No email address added!') 
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(inputs.email)) {
     setError('Invalid email address')
    } else {
      var name = inputs.name;
      var adult = inputs.adult;
      var style = inputs.style;
      var coach = inputs.coach;
      var updates = inputs.updates;
      var interest = inputs.interest;
      var email = inputs.email;

      setError('');
      axios.post(baseURL + '/register_interest', {
        name,
        email,
        adult,
        style,
        coach,
        updates,
        interest
      })
      .then(() => {
        event.target.reset();
        //setInputs('');
        setShowForm(false);
      })
      //TO DO - ADD MORE UI CHAnGES BASED ON SPECIFIC ERROR DATA MESSAGE
      .catch((error) => {
        const emsg = error.response.data.data.error;
        console.log(emsg)
        if (emsg == 'Email already used') {
          event.target.reset();
          setShowReturn(true);
          setShowForm(false);
        } else {
          alert('Snap, something is not quite right! Please try again!!!.'+ emsg)
        }
       
      })
    }
  }
  // Back button navigate to home page
  let navigate = useNavigate(); 
  const routeChange = () =>{ 
    let path = `../`; 
    navigate(path);
  }



  return (   
    <div className="contact-form interest-form">
          { showForm ? (
          <form className="form-container med-width" ref={form} onSubmit={handleSubmit}>
          <h1 className="form-title">Show Your Interest</h1>
          <div className="form-group">
              <label>Your Name <span>*</span></label>
              <input className="form-control" type="text" name='name' placeholder="Name" autoFocus="true" required value={inputs.username} onChange={handleChange}/>
            </div>
            <div className="form-group" style={{flexDirection: 'row'}}>
              <label className='radio-select'>Are you older than 16 years of age? <span>*</span></label>
                <input type="radio" className='radio' id='adult-yes' name='adult' value='true' onChange={handleChange}/>
                <label htmlFor="adult-yes" className='radio-label'>Yes</label>
                <input type="radio" className='radio' id='adult-no' name='adult' value='false' onChange={handleChange}/>
                <label htmlFor="adult-no" className='radio-label'>No</label>
            </div>
            <div className="form-group">
              <label>Your Email <span>*</span></label>
              <input className="form-control" type="email" name="email" placeholder="Email" required value={inputs.email} onChange={handleChange}/>
            </div>
            <div className="form-group">
            <label>What style do you train in?<span>*</span></label>
              <input className="form-control" type="text" name="style" placeholder="Martial Arts Style" required value={inputs.subject} onChange={handleChange}/>
            </div>
            <div className="form-group" style={{flexDirection: 'row'}}>
              <label className='radio-select'>Are you a coach in your art? <span>*</span></label>
                <input type="radio" className='radio' id='coach-yes' name='coach' value='true' onChange={handleChange}/>
                <label htmlFor="coach-yes" className='radio-label'>Yes</label>
                <input type="radio" className='radio' id='coach-no' name='coach' value='false' onChange={handleChange}/>
                <label htmlFor="coach-no" className='radio-label'>No</label>
            </div>
            <div className="form-group" style={{flexDirection: 'row'}}>
              <label>What is your main interest<span>*</span></label>
              <select className="form-control" type="text" name='interest' placeholder="Main Interest" value={inputs.interest} onChange={handleChange}>
              <option value="">Select</option>
                    <option value="Train in Sanda">Train in Sanda</option>
                    <option value="Train to compete">Train to compete</option>
                    <option value="Coach and train">Coach and train</option>
                    <option value="Coach">Coach</option>
                    <option value="Become an official">Become an official</option>
              </select>
            </div>
            <div className="form-group" style={{flexDirection: 'row'}}>
              <label className='radio-select'>Can we notifiy you with updates of the class?<span>*</span></label>
                <input type="radio" className='radio' id='notifications-yes' name='updates' value='true' onChange={handleChange}/>
                <label htmlFor="help-yes" className='radio-label'>Yes</label>
                <input type="radio" className='radio' id='notifications-no' name='updates' value='false' onChange={handleChange}/>
                <label htmlFor="help-no" className='radio-label'>No</label>
            </div>
            <button className="btn btn-primary btn-block" id="contact"  data-bs-hover-animate="shake" disabled={btnDisabled} type="submit">Send</button>
            <span id="alert">{error}</span>
          </form> ) : (
             <div className="form-container"><div className="notice-text">
              { showReturn ? (
                 <p>Thank you {inputs.name} for showing your interest again. If you have any questions you can email john@sanshoukuan.dk directly.</p>
                ):(
                 <p>Thanks for submitting your interest {inputs.name}. You will receive an email shortly.</p>
               )
              }
              </div>
             <button className="btn btn-primary btn-block" id="contact"  data-bs-hover-animate="shake" disabled="" type="button" onClick={routeChange}>Back To Home</button></div>
            )
            }
        </div>
  );
}

export default RegisterInterest;